import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";
import logo from "../logo.jpg";

function Guests() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <CSSTransition in={inView} timeout={100} className="guests">
      <div ref={ref} className="guests">
        <div className="flex_m">
          <img
            src="https://optim.tildacdn.com/tild3035-6262-4964-b631-636234323733/-/resize/449x/-/format/webp/Dear_Guests.png"
            className="text_short"
            alt="logo"
          />
          <img
            src="https://optim.tildacdn.com/tild3761-3532-4933-b037-633131383536/-/resize/451x/-/format/webp/____________.png"
            className="text_long"
            alt="logo"
          />

          <img src={logo} alt="img" className="img"/>
          <span>Вячеслав & Анжелика</span>
          <span>25.08.2024</span>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Guests;

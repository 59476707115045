import React from "react";
import logo from "../logo5.jpg";

function Header() {
  return (
    <div className="header">
      <img src={logo} className="logo" alt="logo" />
      <div className="header_text">
        <span>Вячеслав</span>
        <span>&</span>
        <span>Анжелика</span>
      </div>
    </div>
  );
}

export default Header;

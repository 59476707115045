import React, { useState } from "react";
import "./style.css";
import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";

function Form() {
  const [show, setShow] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "b3ee852f-413d-493c-a01e-649c01004715");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      setShow(!show);
      console.log("aaa");
    }
  };

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <CSSTransition in={inView} timeout={100} className="contacts">
      <section ref={ref} className="contacts">
        <img
          src="https://optim.tildacdn.com/tild3834-3066-4065-b661-623237313630/-/resize/333x/-/format/webp/photo.png"
          className="img"
          alt="logo"
          onClick={() => setShow(!show)}
        />

        <h4 className="h3">Просим вас заполнить анкету до 20 июня</h4>
        <div className="row">
          <form onSubmit={onSubmit}>
            <span>Ваше Имя и Фамилия</span>
            <input
              type="text"
              name="Имя и Фамилия"
              className="box"
              required
              placeholder="Ваше Имя и Фамилия"
              autocomplete="off"
            />

            <span>Планируете ли Вы присутствовать на свадьбе? </span>
            <div className="gender">
              <div>
                <input type="radio" name="Придет?" id="male" value="Да" />
                <label htmlFor="male">Да, с удовольствием!</label>
              </div>
              <div>
                <input
                  className="red"
                  type="radio"
                  name="Придет?"
                  id="female"
                  value="Нет"
                />
                <label htmlFor="female">К сожалению, не смогу</label>
              </div>
            </div>
            <span>Вы придете с парой </span>
            <div className="gender">
              <div>
                <input type="radio" name="С парой?" id="yes" value="да" />
                <label htmlFor="yes">Да</label>
              </div>
              <div>
                <input
                  className="red"
                  type="radio"
                  name="С парой?"
                  id="no"
                  value="нет"
                />
                <label htmlFor="no">Нет</label>
              </div>
            </div>

            <input
              type="submit"
              value="Отправить"
              className="btn"
              name="send"
            />
          </form>
        </div>

        {show && (
          <div className="modal-wrapper">
            <div className="modal-window">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="21"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#63E6BE"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <span>Спасибо! Ваши данные отправлены!</span>
            </div>
            <div onClick={() => setShow(!show)} className="overlay"></div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
}

export default Form;

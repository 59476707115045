import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";

function Plan() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <CSSTransition in={inView} timeout={100} className="plan">
      <div ref={ref} className="plan">
        <div className="plan_top">
          <span className="plan_text">Plan of the Day</span>
        </div>
        <div className="plan_main">
          <div className="plan_img">
            <img
              src="https://optim.tildacdn.com/tild6434-3230-4134-b834-373461636234/-/resize/172x/-/format/webp/noroot.png"
              alt=""
            />
            <div className="line_up"></div>
            <img
              src="https://optim.tildacdn.com/tild3932-6539-4338-b434-306161613630/-/resize/172x/-/format/webp/noroot.png"
              alt=""
            />
            <div className="line_up"></div>
            <img
              src="https://optim.tildacdn.com/tild6164-3161-4139-a162-373430383431/-/resize/172x/-/format/webp/Ellipse_1_copy.png"
              alt=""
            />
          </div>
          <div className="plan_time">
            <div className="plan_disc">
              <span className="plan_disc_main">16:00 Фуршет</span>
              <span className="plan_disc_rare">Встречаемся и настраиваемся</span>
              <span className="plan_disc_rare">на веселую свадьбу</span>
            </div>
            <div className="plan_disc">
              <span className="plan_disc_main">17:00 Церемония </span>
              <span className="plan_disc_rare">Соблюдение и демонстрация</span>
              <span className="plan_disc_rare">национальных традиций</span>
            </div>
            <div className="plan_disc">
              <span className="plan_disc_main">17:30 Начало банкета</span>
              <span className="plan_disc_rare">Сделаем этот вечер</span>
              <span className="plan_disc_rare">незабываемым вместе</span>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Plan;

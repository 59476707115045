import "./App.css";
import Form from "./components/form";
import Header from "./components/header";
import Guests from "./components/guests";
import Place from "./components/place";
import Plan from "./components/plan";

function App() {
  return (
    <div className="App">
      <Header />
      <Guests />
      <Place />
      <Plan />
      <Form />
    </div>
  );
}

export default App;

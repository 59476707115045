import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";

function Place() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  return (
    <CSSTransition in={inView} timeout={100} className="place">
      <div ref={ref}>
        <img
          src="https://optim.tildacdn.com/tild3130-3139-4339-b164-633064393631/-/resize/320x/-/format/webp/Where_.png"
          className="place_img_text"
          alt="logo"
        />

        <span className="place_text">Наш праздник пройдет </span>
        <span className="place_text">В банкетном зале "Bella Grand"</span>
        <span className="place_text">По адресу: г. Алматы,  </span>
        <span className="place_text place_text_b">
          улица Бухтарминская, 124
        </span>
        <a
          className="btn btnPlace"
          target="_blank"
          rel="noreferrer"
          href="https://2gis.kz/almaty/firm/70000001062783287?m=77.038305%2C43.312314%2F16"
        >
          Ссылка на 2GIS
        </a>
      </div>
    </CSSTransition>
  );
}

export default Place;
